const IGNORE_ERROR_PATTERNS = [
  'ResizeObserver loop completed with undelivered notifications',
  'Uncaught "ResizeObserver loop limit exceeded"',
  'XHR error ',
  'Fetch error GET ',
  'Fetch error POST ',
  'SecurityError: The operation is insecure.',
  'node --trace-deprecation',
  'DeprecationWarning',
  'Object Not Found Matching Id:',
];

export { IGNORE_ERROR_PATTERNS };
