import { datadogLogs } from '@datadog/browser-logs';
import { IGNORE_ERROR_PATTERNS } from 'configs';
import { Logger } from 'utils';

const useDatadogLogs = () => {
  const initializeLogger = ({
    clientToken,
    serviceName,
    version,
  }: {
    clientToken: string;
    serviceName: 'creator-suite-frontend' | 'shop-frontend';
    version: string;
  })
  : void => {
    datadogLogs.init({
      beforeSend: (log) => {
        let logThisError = true;
        const { message } = log;

        IGNORE_ERROR_PATTERNS.forEach((error) => {
          if (message?.includes(error)) {
            logThisError = false;
          }
        });

        return logThisError;
      },
      clientToken,
      forwardErrorsToLogs: true,
      service: serviceName,
      sessionSampleRate: 100,
      site: 'datadoghq.eu',
      version,
    });

    datadogLogs.onReady(() => {
      Logger.setLogger(datadogLogs.logger);
    });
  };

  return { initializeLogger };
};

export { useDatadogLogs };
