export default defineNuxtPlugin(async () => {
  const { initSplit } = useSplit();

  let splitClient: SplitIO.IBrowserClient | SplitIO.IClient | undefined;

  const nuxtApp = useNuxtApp();

  if (!nuxtApp.$device.isCrawler) {
    splitClient = await initSplit();
  }

  return { provide: { splitClient } };
});
