export default defineNuxtPlugin(() => {
  const { public: { usercentricsId } } = useRuntimeConfig();

  useScript({
    async: true,
    'data-settings-id': usercentricsId,
    id: 'usercentrics-cmp',
    src: 'https://web.cmp.usercentrics.eu/ui/loader.js',
    type: 'text/javascript',
  });
});
