import { SplitFactory } from '@splitsoftware/splitio';
import { Logger } from 'utils';
import { v4 as uuidV4 } from 'uuid';
import { type FeatureFlagName } from '@/config/ab-tests';
import {
  CHANNEL_NAME,
  SPLIT_EVENTS,
  SPLIT_IO_TREATMENT,
  SPLIT_IO_URL_PARAMS,
  SPLIT_LOGS,
  SPLIT_TRAFFIC_TYPE,
  type SplitIoTreatment,
} from '@/config/splitio';

const useSplit = () => {
  const splitIoUserKey = useCookie<string>('splitIoUserKey', {
    httpOnly: false,
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    maxAge: 365 * 24 * 60 * 60,
    secure: true,
  });

  const treatmentsState = useState<Record<FeatureFlagName, SplitIoTreatment>>(
    'splitTreatments',
    () => ({} as Record<FeatureFlagName, SplitIoTreatment>),
  );

  const route = useRoute();
  const urlParamFeature = route.query[SPLIT_IO_URL_PARAMS.FEATURE] || '';
  const urlParamTreatment = route.query[SPLIT_IO_URL_PARAMS.TREATMENT] || '';
  const urlParamDisabled = route.query[SPLIT_IO_URL_PARAMS.DISABLED] || '';

  const {
    $device,
    $splitClient,
  } = useNuxtApp();

  const createSplitKey = () => {
    if (!splitIoUserKey.value) {
      splitIoUserKey.value = uuidV4();
    }

    return splitIoUserKey.value;
  };

  const initSplit = async () => {
    if (urlParamDisabled) {
      return undefined;
    }

    const { splitApiKeyClient } = useRuntimeConfig().public;

    const newSplitUserKey = createSplitKey();

    const factory = SplitFactory({
      core: {
        authorizationKey: splitApiKeyClient as string,
        ...(import.meta.client
          ? { key: newSplitUserKey ?? '' }
          : {}),
      },
      startup: { readyTimeout: 5 },
    });

    const client = factory.client();

    try {
      await client.ready();
    } catch (e: any) {
      Logger.error(SPLIT_LOGS.SPLIT_ERROR_INITIALIZING, e);
      return undefined;
    }

    return client;
  };

  const getSplitTreatment = (featureFlagName: FeatureFlagName) => {
    if (treatmentsState.value[featureFlagName]) {
      return treatmentsState.value[featureFlagName];
    }

    if (urlParamFeature === featureFlagName) {
      if (urlParamTreatment === SPLIT_IO_TREATMENT.ON) {
        treatmentsState.value[featureFlagName] = SPLIT_IO_TREATMENT.ON;
        return SPLIT_IO_TREATMENT.ON;
      }
      if (urlParamTreatment === SPLIT_IO_TREATMENT.OFF) {
        treatmentsState.value[featureFlagName] = SPLIT_IO_TREATMENT.OFF;
        return SPLIT_IO_TREATMENT.OFF;
      }
    }

    if (!$splitClient) {
      return SPLIT_IO_TREATMENT.OFF;
    }

    const attributes = {
      channel: CHANNEL_NAME,
      mobileDevice: $device.isMobile,
    };

    if (import.meta.server) {
      const treatmentServer = $splitClient?.getTreatment?.(
        splitIoUserKey.value as string,
        featureFlagName,
        attributes,
      ) as SplitIoTreatment;
      treatmentsState.value[featureFlagName] = treatmentServer;
      return treatmentServer;
    }

    const treatmentClient = $splitClient.getTreatment?.(
      featureFlagName,
      attributes,
    ) as SplitIoTreatment;
    treatmentsState.value[featureFlagName] = treatmentClient;
    return treatmentClient;
  };

  const trackEvent = (eventType: typeof SPLIT_EVENTS|string, eventValue: any = null) => {
    if (!$splitClient) {
      return;
    }

    const result = $splitClient.track(SPLIT_TRAFFIC_TYPE.USER, eventType as string, eventValue);

    if (!result) {
      Logger.error(SPLIT_LOGS.SPLIT_ERROR_TRACK, {
        eventType,
        eventValue,
      });
    }
  };

  const isTreatmentOn = (featureFlagName: FeatureFlagName) => getSplitTreatment(featureFlagName) === SPLIT_IO_TREATMENT.ON;

  return {
    getSplitTreatment,
    initSplit,
    isTreatmentOn,
    trackEvent,
    treatments: treatmentsState,
  };
};

export { useSplit };
