<script setup lang="ts">
import {
  colors,
  configureForms,
} from 'shared-components';

configureForms();
</script>

<template>
  <NuxtLoadingIndicator
    :color="colors.purple"
    :height="4"
  />
  <NuxtLayout>
    <NuxtPage class="nuxt-page" />
  </NuxtLayout>
</template>
