import {
  tv,
  type VariantProps,
} from 'tailwind-variants';

const buttonVariants = tv({
  base: 'inline-flex items-center justify-center gap-2 border border-transparent text-sm font-semibold leading-none shadow-xs transition-all duration-150 ease-in-out focus:outline-none disabled:cursor-not-allowed',
  compoundVariants: [
    {

      class: 'disabled:text-grey hover:[&:not([disabled])]:ring-1 focus-visible:[&:not([disabled])]:ring-1',
      variant: [
        'ctaInverted',
        'secondary',
      ],
    },
    {
      class: 'hover:[&:not([disabled])]:border-dark focus-visible:[&:not([disabled])]:border-dark',
      variant: [
        'primary',
        'secondary',
      ],
    },
    {
      class: 'rounded-full',
      isShopButton: true,
    },
    {
      class: 'rounded-md',
      isShopButton: false,
      size: [
        'icon',
        'medium',
        'small',
      ],
    },
    {
      class: 'rounded-lg',
      isShopButton: false,
      size: 'large',
    },
  ],
  defaultVariants: {
    size: 'medium',
    variant: 'cta',
  },
  variants: {
    isShopButton: {
      false: '',
      true: '',
    },
    size: {
      icon: 'p-2',
      large: 'px-8 py-3 text-lg',
      medium: 'h-10 px-4 py-2',
      small: 'h-8 px-2 py-1',
    },
    variant: {
      cta: 'bg-green-dark text-white disabled:bg-green-light hover:[&:not([disabled])]:bg-green focus-visible:[&:not([disabled])]:ring-1 focus-visible:[&:not([disabled])]:ring-green-dark',
      ctaInverted: 'border-green-dark bg-white text-green-dark disabled:border-grey hover:[&:not([disabled])]:ring-green focus-visible:[&:not([disabled])]:ring-green',
      danger: 'hover:[&:not([disabled])]:bg-red-dark disabled:bg-red-light/80 bg-red text-white',
      ghost: 'hover:[&:not([disabled])]:ring-none focus-visible:[&:not([disabled])]:ring-none cursor-pointer text-dark shadow-none hover:bg-dark/10 focus-visible:text-dark',
      info: 'bg-purple text-white hover:[&:not([disabled])]:bg-purple-dark focus-visible:[&:not([disabled])]:ring-1 focus-visible:[&:not([disabled])]:ring-purple',
      outline: 'border-white/50 text-white hover:[&:not([disabled])]:border-white focus-visible:[&:not([disabled])]:border-white focus-visible:[&:not([disabled])]:ring-1 focus-visible:[&:not([disabled])]:ring-white',
      pink: 'bg-pink text-white hover:[&:not([disabled])]:bg-pink/80 focus-visible:[&:not([disabled])]:ring-1  focus-visible:[&:not([disabled])]:ring-pink',
      primary: 'bg-semidark text-white disabled:bg-grey-light hover:[&:not([disabled])]:bg-dark focus-visible:[&:not([disabled])]:ring-1 focus-visible:[&:not([disabled])]:ring-dark',
      secondary: 'border-grey-mid bg-white text-dark hover:[&:not([disabled])]:ring-dark focus-visible:[&:not([disabled])]:ring-dark',
      yellow: 'bg-highlight text-dark hover:[&:not([disabled])]:bg-highlight/80 focus-visible:[&:not([disabled])]:ring-1 focus-visible:[&:not([disabled])]:ring-highlight',
    },
  },

});

type ButtonVariants = VariantProps<typeof buttonVariants>;
const buttonVariantOptions = Object.keys(buttonVariants.variants.variant);

export {
  buttonVariantOptions,
  buttonVariants,
  type ButtonVariants,
};
