import { createGtm } from '@gtm-support/vue-gtm';

export default defineNuxtPlugin(async (nuxtApp) => {
  const router = useRouter();

  const config = {
    gtmId: 'GTM-5PP5ZZMQ',
    source: 'https://sst.juniqe.com/gtm.js',
  };

  const gtm = createGtm({
    compatibility: true,
    defer: false,
    enabled: true,
    id: config.gtmId,
    loadScript: true,
    source: config.source,
    trackOnNextTick: false,

    trackViewEventProperty: 'landingpage_view',
    vueRouter: router,
  });

  nuxtApp.vueApp.use(gtm);
});
